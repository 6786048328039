@import '../../../Shared/shared.styl'

.input_container
	display: flex
	gap: 24px
	@media (max-width: breakPoints.screenSm - 1px)
		gap: 12px

.input_code
	width: 40px !important
	height: 40px
	input
		text-align: center

.old_os_input_code
	@extend .input_code
	color: black
	input
		width: 25px