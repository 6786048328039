@import '../../AppFormStyles.styl'

.form
    .title
        @extend .modal_title
        margin-bottom: 24px

    .input_button_layout
        display: flex
        flex-direction: row

        >:first-child
            width: 1px
            flex-grow: 1
        
        >:last-child
            margin-left: 8px !important
        
        input
            height: 32px

.email
    display: block
    margin-bottom: 1em

[data-mode='bright']
    .form .title
        color: colors.black
    .email
        color: colors.black


.error_message
    font-size: 13px
    line-height: 1em  
    button
        text-decoration: underline