@import "../../Shared/shared.styl"
.profile_image_wrapper
    position: relative 
    width: 88px
    height: 88px
    margin-bottom 24px
    &:hover
        .remove_button
            display: block

.edit_avatar_button
    background: colors.cover_black_color
    color: white
    position absolute
    width: 100%;
    height: calc(24 / 88 * 100%)
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    padding-bottom: 5px

.edit_avatar
    position absolute
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
    
.edit_avatar_round
    @extend .edit_avatar
    border-radius: 50%

.edit_avatar_rect
    @extend .edit_avatar
    border-radius: 4px

.remove_button
    position: absolute
    top: 100%
    display: none
    text-align center
    width: 100%
    color: red 
    text-transform: lowercase
    cursor pointer
    font-size: 12px

.full_profile_image
    img
        object-fit: contain !important