@import '../../Shared/shared.styl'

.initials_wrapper
    position absolute
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: colors.cover_black_color
    background-image: linear-gradient(colors.off_grey, colors.overlay_color)
    display: flex
    justify-content: center
    align-items: center

.letter
    color: white
    font-size: 0.4em
    line-height: 0.4em
