@import '../../../Shared/shared.styl'

.base_container
	margin: auto
	width: 80% !important

.footer_container
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%
	margin-top: 16px
	span
		font-size: 14px

	> button
		padding: 0
	@media (max-width: breakPoints.screenMd - 1px)
		flex-direction: column
		gap: 8px
		align-items: flex-start

.footer_container_left_aligned
	width: 100%
	margin-top: 18px
	padding: 0 !important
	height: auto !important
	font-size: 14px !important

	> span
		display: flex !important
		justify-content: flex-start
		white-space: pre-wrap
		text-align: left

.recover_key_input_container
	width: 100%
	display: flex
	flex-direction: column
	align-items: flex-start
	gap: 4px
	.input
		width: 100%
	.subtitle
		@extend .subtitle3
		color: colors.grey_2

.go_back_button
	display: flex !important
	align-items: center
	margin: 20px auto 0
	color: white !important