
@import '../../Shared/shared.styl'
.password .ant-form-item-explain
    min-height: unset

.signup
    @media (min-width: breakPoints.screenSm - 1px)
        width: calc(100% + 112px)
        margin-left: -56px
        margin-right: -56px

.signup .ant-input-password
    padding: 0

    .ant-input-group .ant-input-affix-wrapper
        padding: 0

.signup .ant-input-password input
    padding-left: 11px
    width: 100%

.signup .ant-input-suffix
    position: absolute
    padding: 0 11px
    right: 0
    top: 0
    height: 100%

.signup .ant-input
.signup .ant-btn:not(.ant-btn-link)
    height: 54px
