@import "../../../Shared/shared.styl"

.step_wrapper
    width: 100%
    display: flex
    align-items: center
    margin-bottom: 1em
    

.step_item
    display: flex
    flex-direction: row
    align-items: center
    .number
        @extend .center
        width: 24px
        height: 24px
        flex-shrink: 0
        border-radius: 50%
        margin-right: 0.5em
        border: 1px solid currentColor
        box-sizing: border-box
    .title
        color: colors.cover_black_color

    &[data-state="active"]
        .number
            border-color: colors.primary_bright
            background-color: colors.primary_bright
            color: white
        .title
            color: inherit

    &[data-state="done"]
        .number
            color: colors.primary_bright

    &[data-state="todo"]
        .number
            color: colors.overlay_color

.line
    display: block
    flex-grow: 1
    height: 1px
    width: 1px
    background-color: colors.overlay_color
    margin: 5px
    &:last-child
        display: none
