@import '../../Shared/shared.styl'

.generic_container
	@media (max-width: breakPoints.screenSm - 1px)
		padding: 0

.div_margin
	margin-bottom: 24px

.header
	@extend .subtitle2, .div_margin
	text-align: center

.body_text
	white-space: pre-wrap
	text-align: center

.primary_button_container
	margin-top: 32px

	@media (max-width:breakPoints.screenMd - 1px)
		min-width: 400px

	@media (max-width:breakPoints.screenSm - 1px)
		min-width: 100%

.primary_button_container + .primary_button_container
	margin-top: 16px

.alert
	@extend .danger_alert
	width: 100%

.qrCode
	width: 240px
	height: 240px
	margin: 24px

.spinner
	margin: 15px !important