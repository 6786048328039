@import '../../../Shared/shared.styl'

sidePadding = 16px
topPadding = 11px

.container
	padding: topPadding 0
	> div + div
		border-top: 0.5px solid colors.surface_contrast
		margin-top: topPadding

[data-mode='bright']
	.container
		border-radius: 4px
		margin: 20px
		box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
		color: black

[data-mode='dark']
	.container
		color: white

.header_main
	display: flex
	flex-direction: column
	gap: 5px
	margin: 0 sidePadding topPadding sidePadding

.header
	@extend .subtitle1
	font-weight: 500

.header_subtitle
	display: flex
	align-items: center
	gap: 4px

.enabled_icon
	color: colors.surface_positive

.set_up_icon
	color: colors.surface_contrast

.set_up_button
	font-size: 14px !important
	padding: 0
	font-weight: 500 !important
	height: auto

.action_container
	display: flex
	align-items: center
	justify-content: space-between
	padding: topPadding sidePadding 0