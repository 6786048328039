@import "../../Shared/shared"

.container
    margin: 24px 0

.title
    @extend .modal_title1
    display: flex
    justify-content: center
    margin-bottom: 16px

.email_container
    width: 100%
    .disabled_input_title
        @extend .subtitle3
        color: colors.grey
    input
        margin-top: 4px

.button_container
    margin-top: 24px
    button
        width: 100%
        font-weight: 500

.center_modal_body
    @extend .subtitle1
    text-align: center
    margin-bottom: 24px