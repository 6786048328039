@import "../../Shared/shared"

.container
    margin: 24px 0
    white-space: pre-wrap

.title
    @extend .modal_title1
    display: flex
    justify-content: center
    margin-bottom: 16px

.email_container
    width: 100%
    .disabled_input_title
        @extend .subtitle3
        color: colors.grey
    input
        margin-top: 4px

.button_container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 16px
    margin-top: 24px

.center_modal_body
    @extend .subtitle1
    text-align: center

.redirect_container
    text-align: center
    margin-top: 32px
    font-size: 14px
