@import '../TwoFAStyles.styl'

.download_button_container
	@extend .primary_button_container
	display: flex
	justify-content: flex-end
	width: 100%
	gap: 15px
	.primary_button_text
		color: colors.primary !important

	// Download and Done buttons
	a, button
		max-width: max-content
		padding: 0 24px

.recovery_keys
	@extend .subtitle1
	margin-top: 24px
	background-color: black
	width: 100%
	color: colors.secondary_teal
	font-family: 'Roboto Mono'
	font-weight: 400
	letter-spacing: 2px
	display: flex
	justify-content: center
	> ul
		padding: 0
		columns: 2
		column-gap: 50px
		margin: 16px 0
		list-style-position: inside
		@media (max-width: breakPoints.screenSm - 1px)
			columns: 1