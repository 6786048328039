@import "../Shared/shared"

background_image = url("../../images/auth_background.png")

.container
    display: flex
    flex-direction: column
    align-items: center
    height: 100%
    width: 100%
    overflow-y: auto
    &[data-mode='dark']
        background: colors.dark_background
        @media (max-width: breakPoints.screenMd - 1px)
            background: colors.bk_1
    :global
        .ant-btn-link
            font-size: inherit
            font-weight: 500

.hidden_scrollbar_container
    @extend .container
    @extend .hide_scrollbar


.content_container
    @extend .center
    flex-grow: 1
    flex-shrink: 0
    width: 100%

    > div
        width: 100%

    @media (max-width: breakPoints.screenLg - 1px)
        align-items: flex-start

.header
    position: relative
    width: 100%
    min-height: 40px
    @media (max-width: breakPoints.screenLg - 1px)
        padding: 0

.footer
    position: relative
    width: 100%
    min-height: 40px

.content_wrapper
    margin: auto
    width: 100%
    max-width: 680px;
    border-radius: 4px
    color: white

    @media (max-width: breakPoints.screenLg - 1px)
        padding: 0 20px

.header_content
    @extend .content_wrapper
    display: flex
    justify-content: flex-end
    position: relative
    top: 40px
    @media (min-width: breakPoints.screenLg)
        max-width: calc((100% - 960px) / 2 + 960px);

.input_block
    background: colors.bk_1
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    :global
        .ant-form
            width: 100%

    @media (max-width: breakPoints.screenSm - 1px)
        padding: 0 40px

    @media (min-width: breakPoints.screenSm)
        padding: 48px 80px

.logo
    height: 40px
    width: auto
    color: colors.primary

.icon_container
    align-self: center

    svg
        color: colors.primary
        width: 100px
        height: 100px
        position: fixed
        top: 50%
        margin-top: -50px

.version
    position: absolute
    color: white
    bottom: 10px
    left: 10px
