@import "../../../Shared/shared.styl";

.app_wrapper
    display: flex
    width: 100%
    flex-direction: column
    align-items: center
    color: inherit
    overflow: auto

.container
    display: flex
    flex-shrink: 0
    flex-direction: column
    align-items: center
    text-align center
    width: $modalWidth
    max-width: calc(100% - 32px)
    .plan_title
        @extend .modal_title

.feature_info_container
    padding: 5px 0

.feature_info_plan_title
    margin-bottom: 16px
    font-size: 24px
    text-align: center

[data-mode="bright"]
    .container
        .plan_title
            color: colors.primary_bright

[data-mode="dark"]
    .container
        .plan_title
            color: white

.plan_info_card
    display: inline-flex
    flex-direction: column
    align-items: center
    text-align: center
    border: 1px solid #D8D8D8
    border-radius: 4px
    padding: 15px
    width: 100%
    height: 100%

.button_style
    margin-top: 24px

.month_price
    line-height: 1.14
    letter-spacing: 0.25px
    text-align: center

    .number
        font-size: 34px;
        font-weight: 500;
        line-height: 1.06;
        margin: 0 5px;

.small_description
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: colors.grey;

.plan_items_wrapper
    margin-top: 0.5em
    margin-bottom: 1.5em
    width: 100%

[data-mode] .link
    padding: 0 4px !important
    height: auto !important
    text-decoration: underline