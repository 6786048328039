@import '../AppFormStyles.styl'

[data-mode]
    .trial_info_wrapper
        margin-bottom: 24px
        @extend .inner_frame_basic
        align-items: flex-start


[data-mode='bright']
    .trial_info_wrapper
        background: colors.grey_white
        color: colors.light_grey
        .modal_title
            color: colors.black

.badge
    position absolute
    left: 0
    background: colors.grey_white
    line-height: 28px
    padding: 0 12px
    border-radius: 0 4px 4px 0
    font-size: 14px
    font-weight: 500

    &[data-color="yellow"]
        background-color: colors.light_yellow
        color: colors.yellow