@import "../../Shared/shared"

.title_container
    @extend .modal_title1
    text-align: center
    margin-top: 24px

.form_container
    width: 100%
    margin: 24px 0

.password_container
    margin-top: 4px