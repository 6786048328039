@import '../../../Shared/shared.styl'

.base_container
	display: flex
	flex-direction: column
	align-items: center
	margin: auto
	gap: 52px
	width: 80% !important
	@media (max-width: breakPoints.screenSm - 1px)
		gap: 25px