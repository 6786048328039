@import "./shared.styl"

.empty_state_wrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .title
        font-size: 34px
        font-weight: 500
        margin-top: 1em

[data-mode="bright"]
    .empty_state_wrapper
        color: colors.black