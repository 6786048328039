@import '../../Shared/shared.styl'

.auth_title
    @extend .subtitle2
    display: flex
    align-items: center
    justify-content: center
    color: white
    font-weight normal
    margin: 24px 0
    svg
        margin-left: 10px
        height: 30px
        width: auto

.auth_description
    @extend .subtitle1
    width: 100%
    text-align: left
    margin-bottom: 16px

.center_description
    text-align: center