@import "../../Shared/shared"

.subtitle
    color: colors.grey_2
    margin-top: 20px
    font-size: 16px
    line-height: 1.5

.spinner
    font-size: 12px
    margin-left: 8px