@import "../Shared/shared"

.form_item
    padding-bottom: 4px
    &:last-child
        padding-bottom: 0
    &.password
        .ant-form-item-explain
            min-height: unset

.form_item_hidden
    height: 0
    visibility: hidden
    margin: 0 !important

.form_block
    pointer-events: none

.footer_actions
    text-align: center
    font-size: 16px
    button
        margin: 0 8px
        font-weight: 500
