@import "../../../Shared/shared"

.inline_container
    display: flex
    justify-content: space-between

    > div
        flex: 1

    @media (max-width: breakPoints.screenSm - 1px)
        flex-direction: column
        margin-bottom: 16px

.inline_space
    flex: 0 0 auto!important
    width: 20px

.bold_text
    font-weight: bold

.form_item
    margin-bottom: 16px!important
    &:last-child
        margin-bottom: 0!important

.form_item_hidden
    margin-bottom: 0!important
    overflow: hidden
    max-height: 0