@import './shared.styl'

.model_container
    .title_area
        padding: 32px 32px 24px
        .title
            @extend .modal_title
            width: 100%
            text-align: center
            margin 0

    .content_area
        padding: 0 32px
    
    .button_area
        border-radius: 0 0 fonts.borderRadius fonts.borderRadius
        padding: 16px
        display: flex
        justify-content: center
        align-items: center

        button
            @extend .button_style
            &:not(:last-child)
                margin-right: 16px

[data-mode='bright']
    .model_container
        .title
            color: colors.black
        .button_area
            background: colors.grey_white

[data-mode='dark']
    .model_container
        .title
            color: white
        .button_area
            background: colors.dark_grey