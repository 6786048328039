@import './shared.styl'

.overlay
    @extend .center
    top: 0
    left: 0
    position: fixed
    width: 100vw
    height: 100vh
    pointer-events: none
    z-index: -1
    opacity: 0
    transition: opacity 200ms

.overlay_blocked
    @extend .overlay
    background-color: colors.overlay_color
    pointer-events: initial
    z-index: 0
    opacity: 1