@import "../Form/ContactFields.styl"
@import '../../AppFormStyles.styl'
@import '../../../Shared/shared.styl'

.app_wrapper
    display: flex
    flex-direction: column
    align-items: center
    overflow: auto
    height: 100%
    padding: 0

.container
    position relative
    width: 100%
    max-width: 100%
    min-width: 240px
    height: 100%
    display: flex
    flex-direction: column
    align-items: center

.form
    width: 100%
    height: 1px
    flex-grow: 1
    display: flex
    flex-direction: column
    align-items: center

.button_text span
    font-weight: 500

.email_section
    overflow-wrap: break-word

.contact_section
    background: colors.grey_white
    display: flex
    padding: 20px
    border-radius: 4px
    margin-top: 20px
    align-items: center
    p 
        flex: 2
        margin-right: 8px
    button
        color: colors.primary_bright
        border: colors.primary_bright 1px solid