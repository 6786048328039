@import '../src/components/Shared/shared.styl'
.fill_form_row
  .ant-form-item
    width: 100%

.grecaptcha-badge
    visibility: hidden

.ant-btn
    outline: none !important

[data-mode] .ant-btn.upgrade_button
    @extends .subtitle1
    color: colors.primary_bright
    border-color: colors.primary_bright
    padding: 4px 24px
    height: auto
    text-transform: capitalize


// Custom styles to separate auth components with app components
// Dark mode isn't enough as some app components can render in dark mode
[data-component='auth']

    // Default button size
    .ant-btn
        font-weight: 500
        line-height: 1.5
        font-size: 16px
        height: 24px // Height of ant-btn-sm

    // Link buttons (text with primary color)
    .ant-btn-link
        font-size: 16px
        font-weight: 400

    .ant-btn-sm
        font-size: 14px

    // Large CTA buttons
    .ant-btn-lg
        height: 54px
        width: 100%

    // Bold link button
    .ant-btn-lg.ant-btn-link
        font-weight: 500
        width: min-content
        height: min-content
        padding: 0px 15px

    .ant-checkbox-wrapper
        font-size: 16px

    a
        color: colors.primary
        text-decoration: none
        font-size: 16px


// Keeping the [data-mode] since it's harder to override twice (to override the dark orverride)
[data-mode='dark']

    ::selection
        background: colors.primary;
        color: black;

    a
        color: colors.primary
        text-decoration: none

    h1, h2, h3, h4, h5, h6, p
        color: white

    .ant-modal-header,
    .ant-modal-footer
        border-color: rgba(white, 0.1)

    .ant-btn-default:not([disabled])
        background: transparent
        color: colors.primary
        border-color: currentColor
        &:hover
            background: transparent
            color: colors.primary
            border-color: colors.primary

    .ant-btn[disabled]
        color: black

    .ant-btn-primary
        text-shadow: none
        color: black !important

    .ant-btn-link
        color: colors.primary !important

    .ant-input-group .ant-input-affix-wrapper:not(:last-child)
        border-right-color: transparent

    .ant-checkbox-input:not(:checked) + .ant-checkbox-inner
        background-color: #0d0d0d

    .ant-input-disabled,
    .ant-input-disabled:hover,
    .ant-input[disabled]
        background: black !important
        color: #7F7F7F
        border-color: #4C4C4C !important

    .ant-input-number-focused
        border-color: #CC8FF7

    .ant-input-number
        &:hover, &:focus
            border-color: #CC8FF7

        .ant-input-number-input
            text-align: center

    .ant-checkbox-checked .ant-checkbox-inner::after
        border: 2px solid black
        border-top: 0
        border-left: 0

    .ant-select-focused
        & .ant-select-selector
            box-shadow: 0 0 0 2px rgba(colors.primary, 0.2) !important

    .ant-select-arrow
        color: colors.light_white

    .ant-select:hover .ant-select-arrow:not(:last-child)
        opacity: 1

    .ant-steps-item-title::after
        background-color: colors.tertiary_grey !important

    .ant-steps-item-finish
        .ant-steps-item-icon
            background-color: colors.black
            border-color: colors.primary
        .ant-steps-item-title::after
            background-color: colors.primary !important

    .ant-select-selector
        .ant-select-selection-search-input
            height: 100% !important

    .ant-steps-item-wait
        .ant-steps-item-icon
            background-color: colors.black
            border-color: colors.tertiary_grey
