@import '../../../Shared/shared.styl'

.plan_item
    display: flex
    align-items: flex-start
    padding: 4px 0
    font-size: 14px
    line-height: 20px
    break-inside: avoid
    svg
        margin-right: 10px
        height: 20px
        flex-shrink: 0
    div
        text-align: initial


[data-mode="bright"]
    .plan_item
        color: colors.black
        svg
            color: colors.primary_bright

[data-mode='dark']
    .plan_item
        color: colors.white
        svg
            color: colors.primary