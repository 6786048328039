colors = json('../../styles/colors.json', { hash: true })

.menu_item
    display: flex
    align-items: center
    justify-content: space-between
    min-width 200px
    width: 100%

.globe_button
    outline: none
    border-radius: 4px
    display: flex
    overflow: hidden
