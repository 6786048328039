@import '../../src/components/Shared/shared.styl'
contentColor = rgba(black, 0.82)

[data-mode='bright']
    color: contentColor

    ::selection
        background: colors.primary_bright;
        color: white;

    a
        color: colors.primary_bright

    // Buttons
    .ant-btn
        border-radius: fonts.borderRadiusLarge

    .ant-btn-default:not([disabled])
        background: transparent
        color: colors.primary_bright
        border-color: currentColor
        &:hover
            background: transparent
            color: colors.primary_bright
            border-color: colors.primary_bright

    .ant-btn-link:not([disabled])
        color: colors.primary_bright
        &:hover
            color: lighten(colors.primary_bright, 5%)

    .ant-btn-primary:not([disabled])
        background: colors.primary_bright
        border-color: colors.primary_bright
        color: white
        &:hover
            border-color: lighten(colors.primary_bright, 5%)
            background-color: lighten(colors.primary_bright, 5%)

    .ant-btn[disabled]
        background-color: colors.grey
        border-color: colors.grey
        color: white

    // Forms
    .ant-form,
    .ant-tooltip-inner,
    .ant-modal-header,
    .ant-modal-content
        background: white
        color: contentColor

    .ant-form-item-has-error
        .ant-input,
        .ant-input-affix-wrapper,
        .ant-input:hover,
        .ant-input-affix-wrapper:hover
            border-color: colors.error_red !important
            background: white !important

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-focused):hover
        border-color: colors.tertiary_grey

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover
        border-color: colors.primary_bright

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input-group-addon,
    .ant-select-single,
    .ant-select .ant-select-selector,
    .ant-checkbox-wrapper
        border-radius: fonts.borderRadiusLarge
        background: transparent
        color: contentColor
        border-color: colors.tertiary_grey
        svg
            color: contentColor

    .ant-select:not(.ant-select-focused):not(.ant-select-disabled):hover .ant-select-selector
        border-color: colors.tertiary_grey

    .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child
        border-top-left-radius: 0
        border-bottom-left-radius: 0
    .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child
        border-top-right-radius: 0
        border-bottom-right-radius: 0

    .ant-input:focus,
    .ant-input-affix-wrapper-focused
        border-color: colors.primary_bright
        box-shadow: 0 0 0 2px rgba(colors.primary_bright, 0.2);

    .ant-input-affix-wrapper-disabled,
    .ant-input-affix-wrapper-disabled:hover,
    .ant-input[disabled]:hover,
    .ant-input[disabled]
        background: colors.bright_disabled_bg !important
        border-color: colors.off_grey !important
        color: colors.off_grey
        svg
            color: colors.off_grey

    .ant-form-item-label
        color: colors.primary_bright

    .ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required::before
        display: none

    .ant-form-item-explain.ant-form-item-explain-error
        color: colors.error_red

    // Slider

    .ant-slider-track
        background: colors.primary_bright
    .ant-slider-rail
        background: colors.grey_white

    .ant-slider .ant-slider-handle
    .ant-slider:hover .ant-slider-handle
        background: white
        border-color: colors.primary_bright

    .ant-slider-step
        background: transparent

    .ant-slider:hover
        .ant-slider-rail
            background: colors.off_grey
        .ant-slider-track
            background: colors.primary_bright

    // Select

    .ant-select-arrow
        color: contentColor

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector
        border-color: colors.primary_bright
        box-shadow: 0 0 0 2px rgba(colors.primary_bright, 0.2);

    .ant-select-dropdown
        background: white

    .ant-select-item
        color: contentColor

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
    .ant-select-item-option-active:not(.ant-select-item-option-disabled)
        color: black
        background-color: colors.grey_white

    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
        color: colors.off_grey

    .ant-select:not(.ant-select-customize-input) .ant-select-selector
        background: white
        border: 1px solid colors.tertiary_grey

    .ant-select:hover .ant-select-arrow:not(:last-child)
        opacity: 1

    // Checkbox
    .ant-checkbox .ant-checkbox-inner
        border-color: colors.tertiary_grey
        background-color: white
        border-radius: fonts.borderRadiusLarge

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner
        border-color: colors.primary_bright

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked:hover .ant-checkbox-inner
        background-color: colors.primary_bright !important
        border-color: colors.primary_bright

    // Notification
    .ant-message-notice-content
        color: contentColor
        background: white
        border-radius: fonts.borderRadiusLarge

    // Spinner
    .ant-spin-dot-item
        background-color: colors.primary_bright
