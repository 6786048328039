@import "../../Shared/shared"

.inline_container
    display: flex
    justify-content: space-between

    > div
        flex: 1

    @media (max-width: breakPoints.screenSm - 1px)
        flex-direction: column

.inline_space
    flex: 0 0 auto!important
    width: 20px

.button_container
    padding-bottom: 12px

.footer
    display: flex
    justify-content: center
    p
        flex-grow: 1

.last_item
    margin-bottom: 14px !important

.recaptcha_text
    font-size: 14px

.description
    text-align: center
    font-size: 16px

.form_fields
    @media (max-width: breakPoints.screenSm - 1px)
        max-height: 40vh
        overflow: auto

// add extra margin to top of next available div after scrollable container
.form_fields + div
    @media (max-width: breakPoints.screenSm - 1px)
        margin-top: 20px

.terms_conditions
    text-align: left
    @media (max-width: breakPoints.screenSm - 1px)
        margin-bottom: 0 !important


.signUpWith
    display: flex
    width: 100%
    gap: 16px
    justify-content: center
    align-items: center
    color: colors.grey_2
    hr
        flex: 1
        height: 1px
        border: none
        background: colors.dark_grey

.passwordHelpText
    margin-bottom: 16px
    text-align: left
    p
        margin-bottom 0
    svg
        margin-right: 8px

.valid
    color: colors.green_1 !important

.social_buttons
    margin: 20px 0 32px
    button
        @extend .subtitle1
        font-weight: 500
        flex: 1
        height: 54px
        background: transparent
        border: 1px solid colors.primary
        display: flex
        justify-content: center
        align-items: center
        gap: 12px
        color: colors.primary
        width: 100%