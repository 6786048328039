@import "../../../Shared/shared"

.bold_text
    font-weight: bold

.form_item
    margin-bottom: 16px!important
    &:last-child
        margin-bottom: 0!important

.form_item_hidden
    margin-bottom: 0!important
    overflow: hidden
    max-height: 0

.total_info
    color: colors.black
    display: flex
    justify-content: space-between
    align-items: flex-end

    .total_title
        @extend .modal_title
        span
            vertical-align: middle
        :first-child
            font-weight: 500

    .total_value
        text-align: right

    .total_value_original
        text-decoration: line-through

    .total_value_final
        @extends .modal_title
        color: colors.primary_bright
        font-weight: 500