@import "../Form/ContactFields.styl"
@import '../../AppFormStyles.styl'

.app_wrapper
    display: flex
    flex-direction: column
    align-items: center
    overflow: auto
    height: 100%
    padding: 0

.container
    position relative
    width: 100%
    max-width: 100%
    min-width: 240px
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    color: colors.black

.form
    width: 100%
    height: 1px
    flex-grow: 1
    display: flex
    flex-direction: column
    align-items: center

[data-mode] .back_button
    padding: 0 0 0 1em !important
    color: colors.light_grey !important
    font-size: 12px !important
    line-height: 1.33
    letter-spacing: 1.8px
    font-weight 900 !important

    .back_icon
        display: block
        position: absolute
        left: 0
        top: 50%
        width: 0.45em
        height: 0.45em
        transform: translate(0, -50%) rotate(45deg)
        border: 1px solid currentColor
        border-right: none
        border-top: none

