@import "../../Shared/shared"

.title_container
    @extend .modal_title1
    margin-top: 24px

.body_container
    @extend .subtitle3
    margin-top: 24px
    text-align: center

.button_container
    display: flex
    justify-content: center
    margin-top: 24px
    width: 100%
    button
        width: 100%
        font-weight: 500