@import "../../../Shared/shared"

.user_volume_block
    display: flex
    flex-direction: row
    padding: 20px 24px
    color: colors.black
    background-color: colors.grey_white
    border-radius: 4px

    .description_area
        display: flex
        flex-direction: column
        flex-grow: 1
        padding-right: 2em

    .description_small
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.4px;
        color: colors.grey;

    .badge
        background-color: colors.primary_bright
        border-radius: 4px
        color: colors.white
        padding: 2px 4px
        margin: 0 2px
        white-space: nowrap

    .input_area
        display: flex
        flex-direction: row
        justify-content: flex-end
        
        input
            display: inline-block
            width: 80px
            height: 27px